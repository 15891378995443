/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.27
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryListApiModel
 */
export interface CategoryListApiModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryListApiModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryListApiModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryListApiModel
     */
    iconUrl?: string | null;
}

/**
 * Check if a given object implements the CategoryListApiModel interface.
 */
export function instanceOfCategoryListApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CategoryListApiModelFromJSON(json: any): CategoryListApiModel {
    return CategoryListApiModelFromJSONTyped(json, false);
}

export function CategoryListApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryListApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'iconUrl': !exists(json, 'iconUrl') ? undefined : json['iconUrl'],
    };
}

export function CategoryListApiModelToJSON(value?: CategoryListApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'iconUrl': value.iconUrl,
    };
}

